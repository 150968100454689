import React from "react";
import Layout from "../../components/layout/Layout";
import { useStaticQuery, graphql } from "gatsby";
import TagsSection from "../../components/ui/sections/TagsSection";
import ImageSection from "../../components/ui/sections/ImageSection";

const EubezpieczeniaonlinePage = () => {
  const query = useStaticQuery(graphql`
    query {
      articleImg: file(
        relativePath: { eq: "portfolio/eubezpieczeniaonline.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `);

  const tags = [
    "Gatsby.js",
    "architektura informacji",
    "integracja z mediami społeczniościowymi",
    "nowoczesny projekt graficzny",
    "intuicyjny interfejs użytkownika",
    "optymalizacja prędkości",
  ];

  return (
    <Layout pageTitle="eubezpieczeniaonline.pl">
      <main className="container mx-auto px-5">
        <ImageSection
          subtitle="Strona marketingowa"
          title="Wizytówka dla agenta ubezpieczeniowego"
          image={query.articleImg.childImageSharp.gatsbyImageData}
        >
          <p className="text-gray-700 text-base mb-4">
            Wykonaliśmy w pełni funkcjonalną stronę marketingową odznaczającą
            się nowoczesnym designem oraz dużą czytelnością. Na stronie zostały
            wykonane optymalizacje pod względem szybkości jej działania.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Strona ma na celu wsparcie kampanii w social mediach i jest w pełni
            zintegrowana z Facebookiem. Strona w połączeniu z mediami
            społeczniośiowymi oraz wyszukiwarką Google stanowi podstawę maszyny
            marketingowej klienta.
          </p>
        </ImageSection>
        <TagsSection title="Projekt w pigułce" tags={tags} />
      </main>
    </Layout>
  );
};

export default EubezpieczeniaonlinePage;
